// external dependencies
import React from 'react';
import Grid from '@material-ui/core/Grid';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';

// local dependencies
import SEO from '../../components/SEO';
import Hero from '../../components/shared/Hero';
import TextBlock from '../../components/shared/TextBlock';
import Section from '../../components/shared/Section';
import Cta from '../../components/shared/Cta';
import ServiceCard from '../../components/shared/ServiceCard';
import EnhancedGrid from '../../components/shared/EnhancedGrid';

// component definition
const ProductInfoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulProductInformationPage {
        page
        metaDescription
        hero {
          buttonLink
          buttonText
          headline
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        twoColumnModule {
          buttonLink
          buttonText
          headline
          bodyCopy {
            bodyCopy
          }
          supertext
          module {
            grid {
              label
              slug
              backgroundImage {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
      }
    }
  `);

  const gridItems = data.page.twoColumnModule.module.grid.map((item, i) => {
    return (
      <Grid item xs={12} sm={12} md={6} key={`grid-${i}`}>
        <ServiceCard
          key={`card-${i}`}
          small
          slug={item.slug}
          image={item.backgroundImage}
          name={item.label}
        />
      </Grid>
    );
  });

  return (
    <>
      <SEO title={data.page.page} description={data.page.metaDescription} />
      <Hero
        short
        bkgImage={data.page.hero.image}
        headline={data.page.hero.headline}
        buttonLink={data.page.hero.buttonLink}
        buttonText={data.page.hero.buttonText}
        supertext={data.page.hero.supertext}
      />
      <Section>
        <EnhancedGrid justifyContent='flex-start' align='flex-start' spacing={3}>
          <Grid item>
            <TextBlock
              superText={data.page.twoColumnModule.supertext}
              headline={data.page.twoColumnModule.headline}
              body={data.page.twoColumnModule.bodyCopy.bodyCopy}
              buttonText={data.page.twoColumnModule.buttonText}
              buttonLink={`/${data.page.twoColumnModule.buttonLink}`}
            />
          </Grid>
          <EnhancedGrid
            wrap='wrap'
            justifyContent='flex-start'
            align='flex-start'
            spacing={2}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            {gridItems}
          </EnhancedGrid>
        </EnhancedGrid>
      </Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
        altText={data.page.ctaSection.title}
      />
    </>
  );
};

export default ProductInfoPage;
